import './about-us.css';

import { Card, Col, Row } from 'react-bootstrap';

import Layout from 'components/layout';
import { Helmet } from "react-helmet";
import React from 'react';
import about from 'images/about.svg';
import arpit from 'images/arpit.svg';
import icon from 'images/icon.svg';
import innovate from 'images/innovate.svg';
import mehul from 'images/mehul.svg';
import mohit from 'images/mohit.svg';
import motivate from 'images/motivate-sale.svg';
import trans from 'images/trans.svg';
import vision from 'images/vision.svg';

const About = () => {
    return (
        <Layout>
            <div>
                <Helmet>
                    <link rel="icon" href={icon} />
                    <title>We believe, Sales is a Game of Motivation</title>
                    <meta name="description" content="Our Mission: Innovate Incentive Compensation, Transparency in Sales Commission, Motivate Sales Teams. Our Leader: Mohit Ambani, Arpit Vasani and Mehul Jain" />
                </Helmet>
                <div>
                    <div className="maindiv">
                        <p className="heading">We believe, Sales is a Game of Motivation</p>
                    </div>
                    <div>
                        <p className="heading2">Our Mission</p>
                    </div>
                </div>
                <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
                    <Col xs={{ span: 10 }} md={4} lg={3} style={{ marginBottom: '30px' }}>
                        <Card className='card2'>
                            <Card.Img className='img' variant="top" src={innovate} />
                            <Card.Title className='title'>Innovate Incentive Compensation</Card.Title>
                            <Card.Text className='text'>To bring innovative models in incentive structure for sales and completely eliminate traditional excel templates. </Card.Text>
                        </Card>
                    </Col>
                    <Col xs={10} md={4} lg={3} style={{ marginBottom: '30px' }}>
                        <Card className='card2'>
                            <Card.Img className='img' variant="top" src={trans} />
                            <Card.Title className='title'>Transparency in Sales Commission</Card.Title>
                            <Card.Text className='text'>To manifest end-to-end visibility in sales commission systems and leave no room for confusion and loopholes.</Card.Text>
                        </Card>
                    </Col>
                    <Col xs={10} md={4} lg={3} style={{ marginBottom: '30px' }}>
                        <Card className='card2'>
                            <Card.Img className='img' variant="top" src={motivate} />
                            <Card.Title className='title'>Motivate Sales Teams</Card.Title>
                            <Card.Text className='text'>To promote sales force motivation in organisations and bring incremental productivity in sales.</Card.Text>
                        </Card>
                    </Col>
                </Row>
                <Row className="alignMent">
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 5 }}>
                        <img src={about} style={{ width: '100%' }} alt="something went wrong" />
                    </Col>
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 5 }}>
                        <div>
                            <p className="Head">About QuotaCal</p>
                            <p className="Desc">At QuotaCal we believe ‘Sales is a Game of Motivation’. During the initial days of our journey, we worked with rockstar sales managers who told us about their pains and day-to-day challenges when it comes to framing the right incentive plan for their salesteam.</p>
                            <p className="Desc">At a later point, we got frustrated as well while building and managing commissions in high-growth companies. Hence we did a lot of research on the problem and also studied how many of the great companies have cracked it. With all the learnings and knowledge combined, we framed a product that became a game-changer for our clients.</p>
                        </div>
                    </Col>
                </Row>
                <Row className="alignMent">
                    <Col xs={{ span: 10, offset: 1, order: 'last' }} md={{ span: 5, order: 'first', offset: 1 }}>
                        <div>
                            <p className="Head">Vision of QuotaCal</p>
                            <p className="Desc">Decode human motivation and link it completely to organisationl goals. Our vision is to see a world where people in the workforce do their jobs because of passion and willingness - not because of the pressure of their bosses and insecurities that comes with the job.</p>
                        </div>
                    </Col>
                    <Col xs={{ span: 10, offset: 1, order: 'first' }} md={{ span: 5, order: 'last' }} >
                        <img src={vision} style={{ width: '100%' }} alt="something went wrong" />
                    </Col>
                </Row>
                <div >
                    <p className="heading">Meet Our Leaders</p>
                </div>
                <Row className="alignMent">
                    <Col xs={10} md={4} lg={3} style={{ marginBottom: '30px' }}>
                        <Card className='card3'>
                            <Card.Img style={{ marginBottom: '30px' }} variant="top" src={mohit} />
                            <Card.Title className='title'>Mohit Ambani</Card.Title>
                            <Card.Text className='text'>CEO & Co-Founder</Card.Text>
                        </Card>
                    </Col>
                    <Col xs={10} md={4} lg={3} style={{ marginBottom: '30px' }}>
                        <Card className='card3'>
                            <Card.Img style={{ marginBottom: '30px' }} variant="top" src={arpit} />
                            <Card.Title className='title'>Arpit Vasani</Card.Title>
                            <Card.Text className='text'>Co-Founder</Card.Text>
                        </Card>
                    </Col>
                    <Col xs={10} md={4} lg={3} style={{ marginBottom: '30px' }}>
                        <Card className='card3'>
                            <Card.Img style={{ marginBottom: '30px' }} variant="top" src={mehul} />
                            <Card.Title className='title'>Mehul Jain</Card.Title>
                            <Card.Text className='text'>Co-Founder</Card.Text>
                        </Card>
                    </Col>
                </Row >
                <div>
                    <p className="heading">Few words from our CEO</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '80px' }}>
                    <div className="ceoDiv">
                        <img src={mohit} id="ceoImg" alt="Something went wrong!" />
                        <p className="words">“Manage your incentives with a breeze. Join us to help companies realise the power of QuotaCal.”</p>
                        <div className="textDiv">
                            <p id="ceoName">Mohit Ambani</p>
                            <p id="ceoPosition">CEO & Co-Founder, QuotaCal</p>
                        </div>
                    </div>
                </div>
            </div >
        </Layout>
    )
};

export default About;
